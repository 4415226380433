// About.js
import React from 'react';

const About = () => {
    return (
        <div style={{ padding: '20px', width: '100vw', textAlign: 'center' }}>
            <p>
                welcome to based
            </p>
            <p>
                buy, sell, trade, deploy, bootstrap erc20s
            </p>
            <p>
                each coin is a standardized fair launch, with no premine, no dev fees, no presale, and no airdrop
            </p>
            <p>
                when a coin completes its bonding curve, the liquidity is pushed to uniswap and fully burned
            </p>
            <p>
                thank you for choosing us &lt;3
            </p>
            <p>
                built w love
            </p>
        </div>
    );
};

export default About;