// web3Onboard.js
import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';

// Initialize injected wallets module (e.g., MetaMask)
const injected = injectedModule();

// Define the supported EVM chains
const chains = [
    {
        id: '0x2105', // Base
        token: 'ETH',
        label: 'Base',
        rpcUrl: 'https://mainnet.base.org',
    }
];

// Initialize Onboard
const onboard = Onboard({
    wallets: [injected],
    chains: chains,
    appMetadata: {
        name: 'based',
        // icon: '<URL_TO_YOUR_APP_ICON>', // Optional
        description: 'based launchpad',
    },
    // Optional: Blocknative API key for enhanced features
    // blocknative: {
    //   apiKey: '<YOUR_BLOCKNATIVE_API_KEY>',
    // },
});

export default onboard;
