// About.js
import React from 'react';

const Disclaimer = () => {
    return (
        <div style={{ padding: '20px' }}>
            <p>
                The information provided herein is intended solely for informational purposes and should not be construed as legal, financial, or investment advice. All tokens accessible through this platform are created by the community and are not endorsed, supported, or verified by Based ("the Company"). The Company expressly disclaims any responsibility or liability for the authenticity, legality, or reliability of any tokens created or distributed through this platform.
            </p>
            <p>
                By accessing or using the Based platform ("the Platform"), you confirm that you have carefully read and fully understood the Rules, Terms of Service, and any additional guidelines or risk disclosures associated with the use of the Platform. You acknowledge and agree that participation involves significant risk, including but not limited to the loss of your funds, fluctuations in token value, or other potential financial consequences.
            </p>
            <p>
                Furthermore, you represent and warrant that you are not a resident, citizen, or entity of the United States, nor are you affiliated with or acting on behalf of any individuals, organizations, or entities listed on the Office of Foreign Assets Control (OFAC) sanctions lists. Your access to and use of the Platform confirms your compliance with all applicable laws and regulations, including but not limited to economic sanctions and anti-money laundering (AML) regulations.
            </p>
            <p>
                The Company reserves the right to deny access to the Platform to any user who fails to meet these requirements or who violates any applicable laws or regulations. The Company is not liable for any losses or damages incurred by users who breach these conditions or engage in unauthorized use of the Platform.
            </p>
            <p>
                By using the Based platform, you explicitly acknowledge and agree to these terms. If you do not agree with any part of this disclaimer, you must discontinue your use of the Platform immediately. The Company reserves the right to modify or update this disclaimer and any related terms at its discretion and without prior notice. Continued use of the Platform signifies your acceptance of any such changes.
            </p>
        </div>
    );
};

export default Disclaimer;