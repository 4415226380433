import React from 'react';

const Footer = () => {
  const footerStyle = {
    height: '3vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    marginBottom: '5px',
  };

  const linkStyle = {
    padding: '10px',
    boxSizing: 'border-box',
    textDecoration: 'none',
    color: 'black',
    margin: '0',
    height: '3vh',
    fontSizing: '1.5vh',
  };
  
  return (
    <footer style={footerStyle}>
      <a href="/about" style={linkStyle}>About</a>
      <a href="/disclaimer" style={linkStyle}>Disclaimer</a>
    </footer>
  );
};

export default Footer;