// DeployToken.js
import React, { useState, useContext, useEffect } from 'react';
import { ethers } from 'ethers';
import { Web3Context } from './Web3Context';
import { useNavigate } from 'react-router-dom';

const DeployToken = ({ writeContract, loadTokens }) => {
  const { wallets } = useContext(Web3Context);
  const navigate = useNavigate();
  const [newToken, setNewToken] = useState({
    name: '',
    symbol: '',
    initAmountIn: '',
    description: '',
    website: '',
    twitter: '',
    telegram: '',
    discord: '',
  });
  const [imageFile, setImageFile] = useState(null); // State to hold the selected image file
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null); // State for image preview URL
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewToken((prevToken) => ({
      ...prevToken,
      [name]: name === 'symbol' ? value.toUpperCase() : value,
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);

      // Generate preview URL
      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
    }
  };

  // Clean up the preview URL when imageFile changes or component unmounts
  useEffect(() => {
    return () => {
      if (imagePreviewUrl) {
        URL.revokeObjectURL(imagePreviewUrl);
      }
    };
  }, [imagePreviewUrl]);

  const generateExtendedDescription = (imageUrl) => {
    const extended = {
      version: '1.0',
      creationDate: new Date().toISOString(),
      tokenType: 'BasedToken',
      initialSupply: '1000000000',
      decimals: 18,
      website: newToken.website || '',
      socialMedia: {
        twitter: newToken.twitter || '',
        telegram: newToken.telegram || '',
        discord: newToken.discord || '',
      },
      tags: [],
      tokenomics: {
        initialPrice: newToken.initAmountIn
          ? `${(1 / parseFloat(newToken.initAmountIn)).toFixed(18)} ETH`
          : 'N/A',
        distribution: 'based certified fair launch',
      },
      image: imageUrl || '',
      additionalInfo: newToken.description,
    };

    return JSON.stringify(extended, null, 2);
  };

  const handleCreateToken = async (e) => {
    e.preventDefault();
    if (!writeContract) {
      setError('Please connect your wallet to create a token.');
      return;
    }

    try {
      const isPaused = await writeContract.paused_();
      if (isPaused) {
        throw new Error('Contract is paused');
      }

      // Upload the image to Pinata directly from the client side
      let imageUrl = '';
      if (imageFile) {
        const formData = new FormData();
        formData.append('file', imageFile);

        // Optional: Add metadata to the request
        const metadata = JSON.stringify({
          name: `${newToken.name}_image`,
        });
        formData.append('pinataMetadata', metadata);

        // Pinata options
        const pinataOptions = JSON.stringify({
          cidVersion: 1,
        });
        formData.append('pinataOptions', pinataOptions);

        const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
          },
          body: formData,
        });

        console.log('Image upload response:', response);

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.error || 'Error uploading file');
        }

        const data = await response.json();
        imageUrl = `/ipfs/${data.IpfsHash}`;
      }

      const creationFee = await writeContract.creationFee_();
      const initAmountInParsed = newToken.initAmountIn
        ? ethers.utils.parseEther(newToken.initAmountIn)
        : ethers.constants.Zero;
      const totalAmount = initAmountInParsed.add(creationFee);

      // Generate extended description with the image URL
      const extendedDescription = generateExtendedDescription(imageUrl);

      const tx = await writeContract.createToken(
        newToken.name,
        newToken.symbol,
        initAmountInParsed,
        newToken.description,
        extendedDescription,
        {
          value: totalAmount,
          gasLimit: 5000000,
        }
      );

      console.log('Transaction sent:', tx.hash);
      const receipt = await tx.wait();
      console.log('Transaction confirmed in block:', receipt.blockNumber);
      console.log('Transaction receipt:', receipt);

      // Extract token address from receipt events
      let tokenAddress = null;
      for (const event of receipt.events) {
        if (event.event === 'TokenCreated') {
          tokenAddress = event.args.tokenAddress || event.args[0];
          break;
        }
      }

      if (tokenAddress) {
        navigate(`/token/${tokenAddress}`);
      } else {
        console.error('TokenCreated event not found, cannot navigate');
      }

      await loadTokens();
      setNewToken({
        name: '',
        symbol: '',
        initAmountIn: '',
        description: '',
        website: '',
        twitter: '',
        telegram: '',
        discord: '',
      });
      setImageFile(null);
      setImagePreviewUrl(null);
    } catch (err) {
      console.error('Error in handleCreateToken:', err);
      setError('Error creating token: ' + (err.error?.message || err.reason || err.message));
    }
  };

  return (
    <div style={{ marginBottom: '40px', padding: '0px 40vw', boxSizing: 'border-box' }}>
      <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>Create New Token</h2>
      {error && (
        <div
          style={{
            backgroundColor: '#ffcccc',
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
          }}
        >
          <strong>Error:</strong> {error}
        </div>
      )}
      <form
        onSubmit={handleCreateToken}
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        <input
          name="name"
          value={newToken.name}
          onChange={handleInputChange}
          placeholder="Token Name"
          required
          style={{ padding: '5px' }}
        />
        <input
          name="symbol"
          value={newToken.symbol}
          onChange={handleInputChange}
          placeholder="Token Symbol"
          required
          style={{ padding: '5px' }}
        />
        <input
          name="initAmountIn"
          value={newToken.initAmountIn}
          onChange={handleInputChange}
          placeholder="Initial Amount (in ETH)"
          type="number"
          step="0.01"
          required
          style={{ padding: '5px' }}
        />
        <textarea
          name="description"
          value={newToken.description}
          onChange={handleInputChange}
          placeholder="Description"
          style={{ padding: '5px', minHeight: '100px' }}
        />
        {/* Image Upload Input */}
        <label style={{ padding: '5px' }}>Image:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          required
          style={{ padding: '5px' }}
        />
        {/* Image Preview */}
        {imagePreviewUrl && (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <img
              src={imagePreviewUrl}
              alt="Image Preview"
              style={{ maxWidth: '100%', maxHeight: '200px' }}
            />
          </div>
        )}
        {/* Optional Fields */}
        <input
          name="website"
          value={newToken.website}
          onChange={handleInputChange}
          placeholder="Website URL (optional)"
          type="url"
          style={{ padding: '5px' }}
        />
        <input
          name="twitter"
          value={newToken.twitter}
          onChange={handleInputChange}
          placeholder="Twitter URL (optional)"
          type="url"
          style={{ padding: '5px' }}
        />
        <input
          name="telegram"
          value={newToken.telegram}
          onChange={handleInputChange}
          placeholder="Telegram URL (optional)"
          type="url"
          style={{ padding: '5px' }}
        />
        <input
          name="discord"
          value={newToken.discord}
          onChange={handleInputChange}
          placeholder="Discord URL (optional)"
          type="url"
          style={{ padding: '5px' }}
        />
        <button
          type="submit"
          style={{
            padding: '10px',
            backgroundColor: '#141414',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Create Token
        </button>
      </form>
    </div>
  );
};

export default DeployToken;
