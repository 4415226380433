import React from 'react';

const DexToolsWidget = ({ pairAddress, chartType = 1, chartResolution = '1D', drawingToolbars = false, tvPlatformColor = 'ffffff', tvPaneColor = 'ffffff', headerColor = 'ffffff', chartInUsd = true }) => {
  const chainId = 'base'; // Set the chainId to 'base'
  const theme = 'light'; // Set the theme to 'light'

  // Construct the widget URL with the given parameters
  const widgetUrl = `https://www.dextools.io/widget-chart/en/${chainId}/pe-light/${pairAddress}?theme=${theme}&chartType=${chartType}&chartResolution=${chartResolution}&drawingToolbars=${drawingToolbars}&tvPlatformColor=${tvPlatformColor}&tvPaneColor=${tvPaneColor}&headerColor=${headerColor}&chartInUsd=${chartInUsd}`;

  return (
    <iframe
      src={widgetUrl}
      width="100%"
      height="500px"
      style={{ border: 'none' }}
      title="based x DEXTools"
    ></iframe>
  );
};

export default DexToolsWidget;