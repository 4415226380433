import React, { useEffect, useState } from 'react';
import { ethers, BigNumber } from 'ethers';
import PropTypes from 'prop-types';

// ABI for the BasedToken contract with necessary functions
const basedTokenABI = [
    // getHoldersLength
    "function getHoldersLength() view returns (uint256)",

    // getHoldersWithBalance
    "function getHoldersWithBalance(uint256 offset, uint256 limit) view returns (address[] memory, uint256[] memory)",

    // balanceOf
    "function balanceOf(address account) view returns (uint256)",

    // name
    "function name() view returns (string)",

    // symbol
    "function symbol() view returns (string)",

    // totalSupply
    "function totalSupply() view returns (uint256)",

    // decimals
    "function decimals() view returns (uint8)",

    // Transfer event (if applicable)
    // "event Transfer(address indexed from, address indexed to, uint256 value)"
];

// ABI for the BasedFoundry contract with Buy and Sell events
const basedFoundryABI = [
    // Buy event
    "event Buy(address indexed token, address indexed sender, uint256 amountIn, uint256 amountOut, address indexed to)",

    // Sell event
    "event Sell(address indexed token, address indexed sender, uint256 amountIn, uint256 amountOut, address indexed to)",

    // Other events can be added here if needed
];

const TopHoldersList = ({ tokenAddress, foundryAddress }) => {
    const [holders, setHolders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [tokenName, setTokenName] = useState('');
    const [tokenSymbol, setTokenSymbol] = useState('');
    const [totalSupply, setTotalSupply] = useState(
        BigNumber.from("1000000000000000000000000000")
      );
    const [decimals, setDecimals] = useState(18); // Default to 18 decimals

    const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
    const INFURA_WS_URL = `wss://base-mainnet.infura.io/ws/v3/${INFURA_PROJECT_ID}`;

    useEffect(() => {
        if (!ethers.utils.isAddress(tokenAddress)) {
            setError('Invalid token address.');
            setLoading(false);
            return;
        }

        if (!ethers.utils.isAddress(foundryAddress)) {
            setError('Invalid foundry address.');
            setLoading(false);
            return;
        }

        // Initialize WebSocket provider
        const provider = new ethers.providers.WebSocketProvider(INFURA_WS_URL);

        // Initialize BasedToken contract
        const tokenContract = new ethers.Contract(tokenAddress, basedTokenABI, provider);

        // Initialize BasedFoundry contract
        const foundryContract = new ethers.Contract(foundryAddress, basedFoundryABI, provider);

        let isMounted = true; // To prevent state updates after unmount

        const fetchTopHolders = async () => {
            try {
                setLoading(true);
                setError('');

                // Fetch token details
                const [name, symbol, supply, tokenDecimals] = await Promise.all([
                    tokenContract.name(),
                    tokenContract.symbol(),
                    tokenContract.totalSupply(),
                    tokenContract.decimals(),
                ]);
                if (!isMounted) return;
                setTokenName(name);
                setTokenSymbol(symbol);
                setTotalSupply(supply);
                setDecimals(tokenDecimals);

                // Get total number of holders
                const totalHoldersBN = await tokenContract.getHoldersLength();
                const totalHolders = totalHoldersBN.toNumber();

                const batchSize = 100; // Number of holders to fetch per batch
                let fetchedHolders = [];
                let offset = 0;

                while (offset < totalHolders) {
                    const limit = Math.min(batchSize, totalHolders - offset);
                    const [addresses, balances] = await tokenContract.getHoldersWithBalance(offset, limit);

                    // Combine addresses and balances
                    const batch = addresses.map((address, index) => ({
                        address,
                        balance: balances[index],
                    }));    

                    console.log('balances:', batch.map(holder => holder.balance.toString()));
                    console.log('totalsupply:', totalSupply.toString());

                    fetchedHolders = fetchedHolders.concat(batch);
                    offset += limit;
                }

                // Filter out holders with zero balance
                fetchedHolders = fetchedHolders.filter(holder => holder.balance.gt(0));

                // Sort holders by balance in descending order
                fetchedHolders.sort((a, b) => {
                    if (a.balance.gt(b.balance)) return -1;
                    if (a.balance.lt(b.balance)) return 1;
                    return 0;
                });

                // Take top 20 holders
                const top20Holders = fetchedHolders.slice(0, 20);

                // Calculate ownership percentage
                const top20WithPercentage = top20Holders.map(holder => {
                    // Ensure totalSupply is a valid BigNumber
                    if (!totalSupply || !BigNumber.isBigNumber(totalSupply)) {
                        return {
                            address: holder.address,
                            percentage: "0.00",
                        };
                    }

                    // Convert balance and total supply to floating point values considering decimals
                    const balanceFloat = parseFloat(ethers.utils.formatUnits(holder.balance, decimals));
                    const totalSupplyFloat = parseFloat(ethers.utils.formatUnits(totalSupply, decimals));

                    // Avoid division by zero
                    const percentage = totalSupplyFloat === 0 
                        ? "0.00" 
                        : ((balanceFloat / totalSupplyFloat) * 100).toFixed(2);
                    return {
                        address: holder.address,
                        percentage: percentage,
                    };
                });

                if (isMounted) {
                    setHolders(top20WithPercentage);
                }
            } catch (err) {
                console.error(err);
                if (isMounted) {
                    setError(err.message || 'An error occurred while fetching holders.');
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        // Initial fetch
        fetchTopHolders();

        // Event listeners for Buy and Sell events to update holders
        const handleBuy = (token, sender, amountIn, amountOut, to, event) => {
            if (token.toLowerCase() === tokenAddress.toLowerCase()) {
                console.log(`Buy event detected for token ${token} from ${sender} to ${to} of amount ${amountOut.toString()}`);
                fetchTopHolders();
            }
        };

        const handleSell = (token, sender, amountIn, amountOut, to, event) => {
            if (token.toLowerCase() === tokenAddress.toLowerCase()) {
                console.log(`Sell event detected for token ${token} from ${sender} to ${to} of amount ${amountIn.toString()}`);
                fetchTopHolders();
            }
        };

        // Subscribe to Buy and Sell events
        foundryContract.on('Buy', handleBuy);
        foundryContract.on('Sell', handleSell);

        // Cleanup function
        return () => {
            isMounted = false;
            foundryContract.off('Buy', handleBuy);
            foundryContract.off('Sell', handleSell);
            provider.removeAllListeners();
            provider.destroy(); // Close the WebSocket connection
        };
    }, [tokenAddress, foundryAddress, INFURA_WS_URL]);

    if (error) {
        return <div style={{ color: 'red', paddingTop: '50px', boxSizing: 'border-box' }}>Error: {error}</div>;
    }

    if (loading) {
        return <div style={{ paddingTop: '50px', boxSizing: 'border-box' }}>Loading...</div>;
    }

    if (holders.length === 0) {
        return <div style={{ paddingTop: '50px', boxSizing: 'border-box' }}>No holders found.</div>;
    }

    return (
        <div style={{ paddingTop: '50px', boxSizing: 'border-box' }}>
            <h3>top holders</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={styles.th}>address</th>
                        <th style={styles.th}>ownership</th>
                    </tr>
                </thead>
                <tbody>
                    {holders.map((holder, index) => (
                        <tr key={holder.address} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                            <td style={styles.td}>
                                <a
                                    style={{ textDecoration: 'none', color: 'black' }}
                                    href={`https://basescan.org/address/${holder.address}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {holder.address === '0xF8a7F68562aA5279C8e8A653104B7864ae53dE5a' 
                                        ? `${holder.address.slice(0, 6)}...${holder.address.slice(-4)} (foundry)` 
                                        : `${holder.address.slice(0, 6)}...${holder.address.slice(-4)}`}
                                </a>
                            </td>
                            <td style={styles.td}>{holder.percentage}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// Define PropTypes
TopHoldersList.propTypes = {
    tokenAddress: PropTypes.string.isRequired,
    foundryAddress: PropTypes.string.isRequired, // New prop for Foundry contract address
};

// Simple styling without borders or backgrounds
const styles = {
    th: {
        padding: '8px',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    td: {
        padding: '8px',
        textAlign: 'left',
        wordBreak: 'break-all',
    },
    evenRow: {
        backgroundColor: '#f9f9f9',
    },
    oddRow: {
        backgroundColor: '#ffffff',
    },
};

export default TopHoldersList;
