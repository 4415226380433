import React, { useContext, useEffect, useState } from "react";
import { Web3Context } from "../Web3Context";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import "./Comments.css";

const COMMENTS_LIMIT = 50;

const Comments = ({ tokenAddress }) => {
  const { wallets } = useContext(Web3Context);
  const [comments, setComments] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [hasMore, setHasMore] = useState(true);

  // Fetch initial comments
  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress]);

  const fetchComments = async () => {
    setLoading(true);
    try {
      const commentsRef = collection(db, "comments");
      const q = query(
        commentsRef,
        where("tokenAddress", "==", tokenAddress),
        orderBy("timestamp", "desc"),
        limit(COMMENTS_LIMIT)
      );

      const snapshot = await getDocs(q);
      const commentsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setComments(commentsData);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

      setHasMore(snapshot.docs.length === COMMENTS_LIMIT);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
    setLoading(false);
  };

  const loadMoreComments = async () => {
    if (!lastVisible) return;
    setLoading(true);
    try {
      const commentsRef = collection(db, "comments");
      const q = query(
        commentsRef,
        where("tokenAddress", "==", tokenAddress),
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(COMMENTS_LIMIT)
      );

      const snapshot = await getDocs(q);
      const moreComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setComments((prevComments) => [...prevComments, ...moreComments]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

      setHasMore(snapshot.docs.length === COMMENTS_LIMIT);
    } catch (error) {
      console.error("Error loading more comments:", error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!wallets || wallets.length === 0) {
      alert("Please connect your wallet to comment.");
      return;
    }
    if (!newComment.trim()) {
      alert("Comment cannot be empty.");
      return;
    }

    try {
      const commentsRef = collection(db, "comments");
      await addDoc(commentsRef, {
        tokenAddress,
        userAddress: wallets[0].accounts[0].address,
        content: newComment.trim(),
        timestamp: serverTimestamp(),
      });
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const truncateAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  return (
    <div className="comments-section">

      {/* Comment Form */}
      <form onSubmit={handleSubmit}>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="write your comment..."
          rows="3"
          required
          disabled={!wallets || wallets.length === 0} // Disable textarea if no wallet is connected
        />
        <button
          type="submit"
          disabled={!wallets || wallets.length === 0} // Disable button if no wallet is connected
        >
          {wallets && wallets.length > 0 ? "submit" : "login to comment"}
        </button>
      </form>

      {/* Comments List */}
      <div className="comments-list">
        {comments.map((comment) => (
          <div key={comment.id} className="comment">
            <div className="comment-header">
              <span className="username">
                {truncateAddress(comment.userAddress)}
              </span>
              <span className="timestamp">
                {formatTimestamp(comment.timestamp)}
              </span>
            </div>
            <div className="comment-content">{comment.content}</div>
          </div>
        ))}
      </div>

      {/* Load More Button */}
      {hasMore && (
        <button onClick={loadMoreComments} disabled={loading}>
          {loading ? "Loading..." : "Load More"}
        </button>
      )}
    </div>
  );
};

export default Comments;
