// TokenPage.js
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { Web3Context } from './Web3Context';
import './TokenPage.css';

// Import ABIs
import BasedTokenABI from './abi/BasedToken.json';
import BasedFoundryABI from './abi/BasedFoundry.json';
import BasedRouterABI from './abi/BasedRouter.json';

// Import Lightweight Charts
import { createChart } from 'lightweight-charts';
import DexToolsWidget from './DexToolsWidget';
import TopHoldersList from './components/TopHoldersList';
import Comments from './components/Comments';

// Import CSS for flash animation
import './TokenPage.css'; // Ensure this CSS file is created and imported

const TokenPage = () => {
  const { address } = useParams(); // Get the token address from URL
  const { provider, signer } = useContext(Web3Context);

  const [infuraProvider, setInfuraProvider] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [extendedData, setExtendedData] = useState(null); // New state for parsed extended data
  const [historicalStats, setHistoricalStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Replace with your BasedFoundry contract addresses
  const BASED_FOUNDRY_ADDRESS = process.env.REACT_APP_BASED_FOUNDRY_ADDRESS;
  const BASED_ROUTER_ADDRESS = process.env.REACT_APP_BASED_ROUTER_ADDRESS;

  // Interval for refreshing historical data (in milliseconds)
  const REFRESH_INTERVAL_MS = 5000; // 5 seconds

  // State for Buy and Sell Forms
  const [buyAmount, setBuyAmount] = useState('');
  const [sellAmount, setSellAmount] = useState('');
  const [transactionStatus, setTransactionStatus] = useState(null); // 'buying', 'selling', 'success', 'error'
  const [transactionMessage, setTransactionMessage] = useState('');

  // State for Balances
  const [ethBalance, setEthBalance] = useState('');
  const [tokenBalance, setTokenBalance] = useState('');

  // State for Bonding Curve Progress
  const [ethReserve, setEthReserve] = useState('');
  const [graduationThreshold, setGraduationThreshold] = useState('');

  // Refs for the chart containers
  const priceChartContainerRef = useRef(null);

  // Refs for chart instances
  const priceChartRef = useRef(null);

  // State for Flash Animations
  const [previousExpectedBuyAmount, setPreviousExpectedBuyAmount] = useState(null);
  const [previousExpectedSellAmount, setPreviousExpectedSellAmount] = useState(null);
  const [buyFlash, setBuyFlash] = useState(false);
  const [sellFlash, setSellFlash] = useState(false);

  // Initialize Infura provider
  useEffect(() => {
    const initInfuraProvider = () => {
      const infuraUrl = process.env.REACT_APP_BASE_INFURA_URL;
      if (!infuraUrl) {
        console.error('Infura URL not set in .env file');
        setError('Infura URL not set in .env file');
        return;
      }
      const provider = new ethers.providers.JsonRpcProvider(infuraUrl);
      setInfuraProvider(provider);
    };

    initInfuraProvider();
  }, []);

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        if (!ethers.utils.isAddress(address)) {
          throw new Error('Invalid token address.');
        }

        // Initialize token contract with Infura provider
        const tokenContract = new ethers.Contract(address, BasedTokenABI.abi, infuraProvider);

        // Initialize BasedFoundry contract with Infura provider
        const basedFoundryRead = new ethers.Contract(
          BASED_FOUNDRY_ADDRESS,
          BasedFoundryABI.abi,
          infuraProvider
        );

        // Fetch metadata
        const metadata = await tokenContract.getMetadata();

        // Fetch the market cap from the BasedFoundry contract
        const pool = await basedFoundryRead.getPool(address);
        const mcapInEth = ethers.utils.formatEther(pool.lastMcapInEth);

        setTokenData({
          address,
          name: metadata.name,
          symbol: metadata.symbol,
          description: metadata.description,
          extended: metadata.extended,
          mcap: mcapInEth, // Correctly set the market cap from the pool data
          isGraduated: metadata.isGraduated,
          creator: metadata.creator,
        });

        // Parse extended data
        try {
          const parsedExtended = JSON.parse(metadata.extended);
          setExtendedData(parsedExtended);
        } catch (e) {
          console.error('Failed to parse extended data:', e);
          setExtendedData(null); // or set an error message if desired
        }
      } catch (err) {
        console.error('Error fetching token data:', err);
        setError(err.message);
      }
    };

    const fetchBondingCurveProgress = async () => {
      try {
        // Initialize BasedFoundry contract with Infura provider
        const basedFoundryRead = new ethers.Contract(
          BASED_FOUNDRY_ADDRESS,
          BasedFoundryABI.abi,
          infuraProvider
        );

        // Get the pool data for the token
        const pool = await basedFoundryRead.getPool(address);

        // ETH Reserve in the pool
        const ethReserve = ethers.utils.formatEther(pool.ethReserve);
        if (ethReserve === '0.0') {
          console.log('ETH Reserve is 0.0 ETH');
        } else {
          setEthReserve(ethReserve);
        }

        // Graduation Threshold
        const graduationThreshold = await basedFoundryRead.graduationThreshold_();
        if (graduationThreshold === '0') {
          console.log('Graduation Threshold is 0');
        } else {
          setGraduationThreshold(ethers.utils.formatEther(graduationThreshold));
        }
      } catch (err) {
        console.error('Error fetching bonding curve progress:', err);
        setError('Error fetching bonding curve progress: ' + (err.data?.message || err.message));
      }
    };

    const fetchData = async () => {
      if (infuraProvider) {
        await fetchTokenData();
        await fetchHistoricalStats(true); // Initial fetch
        await fetchBondingCurveProgress();
      }
    };

    fetchData();
    // Only rerun if address or infuraProvider changes
  }, [address, infuraProvider]);

  // Function to fetch historical stats
  const fetchHistoricalStats = async (isInitial = false) => {
    try {
      const query = `
        query Trades($tokenAddress: Bytes!) {
          buys(where: { token: $tokenAddress }, first: 1000, orderBy: blockTimestamp, orderDirection: asc) {
            id
            token
            amountIn
            amountOut
            sender
            to
            blockTimestamp
          }
          sells(where: { token: $tokenAddress }, first: 1000, orderBy: blockTimestamp, orderDirection: asc) {
            id
            token
            amountIn
            amountOut
            sender
            to
            blockTimestamp
          }
        }
      `;

      const variables = { tokenAddress: address.toLowerCase() };

      const response = await fetch('https://api.studio.thegraph.com/query/82775/basedfoundry/version/latest', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query, variables }),
      });

      const data = await response.json();
      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      // Combine buys and sells into a single array of trades
      const trades = [];

      data.data.buys.forEach((trade) => {
        trades.push({
          isBuy: true,
          amountIn: ethers.BigNumber.from(trade.amountIn),
          amountOut: ethers.BigNumber.from(trade.amountOut),
          timestamp: parseInt(trade.blockTimestamp),
          txHash: trade.txHash,
        });
      });

      data.data.sells.forEach((trade) => {
        trades.push({
          isBuy: false,
          amountIn: ethers.BigNumber.from(trade.amountIn),
          amountOut: ethers.BigNumber.from(trade.amountOut),
          timestamp: parseInt(trade.blockTimestamp),
          txHash: trade.txHash,
        });
      });

      // Sort trades by timestamp
      trades.sort((a, b) => a.timestamp - b.timestamp);

      setHistoricalStats({ trades });

      if (isInitial) {
        setLoading(false); // Only set loading to false after the initial fetch
      }
    } catch (err) {
      console.error('Error fetching historical stats:', err);
      setError(err.message);
      if (isInitial) {
        setLoading(false); // Ensure loading is set to false if there's an error
      }
    }
  };

  // Function to fetch balances
  const fetchBalances = async () => {
    try {
      if (signer) {
        const userAddress = await signer.getAddress();

        // Fetch ETH Balance
        const balance = await provider.getBalance(userAddress);
        setEthBalance(ethers.utils.formatEther(balance));

        // Fetch Token Balance
        const tokenContract = new ethers.Contract(address, BasedTokenABI.abi, signer);
        const tokenBal = await tokenContract.balanceOf(userAddress);
        setTokenBalance(ethers.utils.formatEther(tokenBal));
      }
    } catch (err) {
      console.error('Error fetching balances:', err);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, [signer, address]);

  const fetchBondingCurveProgress = async () => {
    try {
      // Initialize BasedFoundry contract with Infura provider
      const basedFoundryRead = new ethers.Contract(
        BASED_FOUNDRY_ADDRESS,
        BasedFoundryABI.abi,
        infuraProvider
      );

      // Get the pool data for the token
      const pool = await basedFoundryRead.getPool(address);

      // ETH Reserve in the pool
      const ethReserve = ethers.utils.formatEther(pool.ethReserve);
      if (ethReserve === '0.0') {
        console.log('ETH Reserve is 0.0 ETH');
      } else {
        setEthReserve(ethReserve);
      }

      // Graduation Threshold
      const graduationThreshold = await basedFoundryRead.graduationThreshold_();
      if (graduationThreshold === '0') {
        console.log('Graduation Threshold is 0');
      } else {
        setGraduationThreshold(ethers.utils.formatEther(graduationThreshold));
      }
    } catch (err) {
      console.error('Error fetching bonding curve progress:', err);
      setError('Error fetching bonding curve progress: ' + (err.data?.message || err.message));
    }
  };

  // Initialize BasedFoundry contract with signer for write operations
  const basedFoundryContract = signer
    ? new ethers.Contract(BASED_FOUNDRY_ADDRESS, BasedFoundryABI.abi, signer)
    : null;

  // Initialize BasedRouter contract with signer for write operations
  const basedRouterContract = signer
    ? new ethers.Contract(BASED_ROUTER_ADDRESS, BasedRouterABI.abi, signer)
    : null;

  // Handle Buy Tokens
  const handleBuy = async (e) => {
    e.preventDefault();
    setError('');
    setTransactionStatus('buying');
    setTransactionMessage('initiating buy transaction...');

    try {
      const amountIn = ethers.utils.parseEther(buyAmount);
      const amountOutMin = 0; // You can set a minimum amount based on slippage tolerance
      const to = await signer.getAddress();
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from current time

      let tx;

      if (tokenData.isGraduated) {
        if (!basedRouterContract) throw new Error('Please connect your wallet to buy tokens.');

        const path = ['0x4200000000000000000000000000000000000006', address]; // [WETH, token]
        tx = await basedRouterContract.swapExactETHForTokens(
          amountOutMin,
          path,
          to,
          deadline,
          '0x0c778e66efa266b5011c552C4A7BDA63Ad24C37B', // referrer
          '0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24', // Uniswap V2 Router
          {
            value: amountIn,
            gasLimit: 12000000,
          }
        );
      } else {
        if (!basedFoundryContract) throw new Error('Please connect your wallet to buy tokens.');

        tx = await basedFoundryContract.swapEthForTokens(
          address,
          amountIn,
          amountOutMin,
          to,
          deadline,
          {
            value: amountIn,
            gasLimit: 12000000,
          }
        );
      }

      setTransactionMessage('transaction submitted. waiting for confirmation...');
      await tx.wait();

      await fetchBalances(); // Refresh balances
      await fetchBondingCurveProgress(); // Refresh bonding curve progress

      setTransactionStatus('success');
      setTransactionMessage('tokens purchased successfully!');
    } catch (err) {
      console.error('Error buying tokens:', err);
      setTransactionStatus('error');
      setTransactionMessage('error buying tokens.');
    }
  };

  // Handle Sell Tokens
  const handleSell = async (e) => {
    e.preventDefault();
    setError('');
    setTransactionStatus('selling');
    setTransactionMessage('initiating sell transaction...');

    try {
      const amountIn = ethers.utils.parseEther(sellAmount);
      const amountOutMin = 0; // You can set a minimum amount based on slippage tolerance
      const to = await signer.getAddress();
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from current time

      let tx;

      if (tokenData.isGraduated) {
        if (!basedRouterContract) throw new Error('Please connect your wallet to sell tokens.');

        const path = [address, '0x4200000000000000000000000000000000000006']; // [token, WETH]

        // Approve the BasedRouter to spend tokens
        const tokenContract = new ethers.Contract(address, BasedTokenABI.abi, signer);
        await tokenContract.approve(BASED_ROUTER_ADDRESS, amountIn);

        tx = await basedRouterContract.swapExactTokensForETH(
          amountIn,
          amountOutMin,
          path,
          to,
          deadline,
          '0x0c778e66efa266b5011c552C4A7BDA63Ad24C37B', // referrer
          '0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24', // Uniswap V2 Router
          { gasLimit: 2000000 }
        );
      } else {
        if (!basedFoundryContract) throw new Error('Please connect your wallet to sell tokens.');

        tx = await basedFoundryContract.swapTokensForEth(
          address,
          amountIn,
          amountOutMin,
          to,
          deadline,
          {
            gasLimit: 2000000,
          }
        );
      }

      setTransactionMessage('sell transaction submitted. waiting for confirmation...');
      await tx.wait();

      await fetchBalances(); // Refresh balances
      await fetchBondingCurveProgress(); // Refresh bonding curve progress

      setTransactionStatus('success');
      setTransactionMessage('tokens sold successfully!');
    } catch (err) {
      console.error('Error selling tokens:', err);
      setTransactionStatus('error');
      setTransactionMessage('error selling tokens');
    }
  };

  // Function to aggregate trades into candles and avoid gaps
  const aggregateTradesToCandles = (trades) => {
    const candles = [];
    let previousClose = null;

    for (let trade of trades) {
      // Compute ETH and Token amounts
      const ethAmount = trade.isBuy
        ? parseFloat(ethers.utils.formatEther(trade.amountIn))
        : parseFloat(ethers.utils.formatEther(trade.amountOut));

      const tokenAmount = trade.isBuy
        ? parseFloat(ethers.utils.formatEther(trade.amountOut))
        : parseFloat(ethers.utils.formatEther(trade.amountIn));

      // Compute price
      const price = ethAmount / tokenAmount;

      // Open price is previous candle's close price or the price of the first trade
      const open = previousClose !== null ? previousClose : price;
      const close = price;

      // Compute high and low based on price movement
      let high, low;
      if (close >= open) {
        high = close;
        low = open;
      } else {
        high = open;
        low = close;
      }

      // Compute volume (in ETH)
      const volume = ethAmount;

      // Create a candle for this trade
      const candle = {
        time: trade.timestamp, // Set candle time to trade timestamp
        open: open,
        high: high,
        low: low,
        close: close,
        volume: volume,
      };

      candles.push(candle);
      previousClose = close;
    }

    return candles;
  };

  // Initialize the chart when component mounts
  useEffect(() => {
    if (!loading && priceChartContainerRef.current && !tokenData.isGraduated) {
      // Initialize the chart
      const priceChart = createChart(priceChartContainerRef.current, {
        width: priceChartContainerRef.current.offsetWidth,
        height: 600,
        layout: {
          textColor: '#000000',
          backgroundColor: '#FFFFFF',
        },
        grid: {
          vertLines: {
            color: '#e0e0e0',
          },
          horzLines: {
            color: '#e0e0e0',
          },
        },
        timeScale: {
          borderColor: '#e0e0e0',
          timeVisible: true, // Show time labels since we now have accurate times
        },
      });
      const candlestickSeries = priceChart.addCandlestickSeries({
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderDownColor: '#ef5350',
        borderUpColor: '#26a69a',
        wickDownColor: '#ef5350',
        wickUpColor: '#26a69a',
        priceFormat: {
          type: 'custom',
          formatter: (price) => price.toFixed(12),
        },
      });
      priceChartRef.current = { chart: priceChart, series: candlestickSeries };

      // Clean up on component unmount
      return () => {
        priceChart.remove();
      };
    }
  }, [loading, tokenData]);

  // Update chart data when historicalStats changes
  useEffect(() => {
    if (historicalStats && historicalStats.trades.length > 0 && priceChartRef.current) {
      // Process trades to generate candlestick data
      const candlesData = aggregateTradesToCandles(historicalStats.trades);

      // Update price chart data
      if (priceChartRef.current.series) {
        priceChartRef.current.series.setData(candlesData);
      }
    }
  }, [historicalStats]);

  // Periodically fetch historical data every REFRESH_INTERVAL_MS milliseconds
  useEffect(() => {
    let intervalId;

    if (tokenData && !tokenData.isGraduated) {
      intervalId = setInterval(() => {
        fetchHistoricalStats();
      }, REFRESH_INTERVAL_MS);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [tokenData]);

  const progressPercentage =
    ethReserve && graduationThreshold
      ? (parseFloat(ethReserve) / parseFloat(graduationThreshold)) * 100
      : 0;

  // Prepare image URL from extended data
  let imageUrl = '';
  if (extendedData && extendedData.image) {
    if (extendedData.image.startsWith('/ipfs/')) {
      imageUrl = `https://gateway.pinata.cloud${extendedData.image}`;
    } else {
      imageUrl = extendedData.image;
    }
  }

  const [uniswapPairAddress, setUniswapPairAddress] = useState('');

  // Function to calculate Uniswap V2 pair address
  const calculateUniswapPairAddress = (factoryAddress, tokenAddress, wethAddress) => {
    const [token0, token1] =
      tokenAddress.toLowerCase() < wethAddress.toLowerCase()
        ? [tokenAddress.toLowerCase(), wethAddress.toLowerCase()]
        : [wethAddress.toLowerCase(), tokenAddress.toLowerCase()];

    const keccak256 = ethers.utils.keccak256;
    const pack = ethers.utils.solidityPack;

    const salt = keccak256(pack(['address', 'address'], [token0, token1]));

    const initCodeHash = '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f';

    const create2Inputs = [
      '0xff',
      factoryAddress,
      salt,
      initCodeHash,
    ];

    const encoded = '0x' + create2Inputs.map(i => i.slice(2)).join('');
    const addressBytes = keccak256(encoded).slice(-40);
    const pairAddress = ethers.utils.getAddress('0x' + addressBytes);

    return pairAddress;
  };

  // Fetch Uniswap pair address for graduated tokens
  useEffect(() => {
    const fetchUniswapPairAddress = async () => {
      if (tokenData && tokenData.isGraduated) {
        const factoryAddress = '0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6'; // Uniswap V2 Factory
        const wethAddress = '0x4200000000000000000000000000000000000006'; // WETH address
        const pairAddress = calculateUniswapPairAddress(factoryAddress, address, wethAddress);
        setUniswapPairAddress(pairAddress);
      }
    };

    fetchUniswapPairAddress();
  }, [tokenData, address]);

  const [expectedBuyAmount, setExpectedBuyAmount] = useState(null);
  const [expectedSellAmount, setExpectedSellAmount] = useState(null);

  // Function to calculate expected ETH output from token input
  const calculateExpectedSellAmount = async (amountIn) => {
    if (!amountIn || isNaN(amountIn) || Number(amountIn) <= 0) {
      setExpectedSellAmount(null);
      return;
    }

    try {
      const amountInWei = ethers.utils.parseEther(amountIn.toString());
      const basedFoundryContract = new ethers.Contract(BASED_FOUNDRY_ADDRESS, BasedFoundryABI.abi, infuraProvider);
      const amountOut = await basedFoundryContract.calcAmountOutFromToken(address, amountInWei);
      setExpectedSellAmount(ethers.utils.formatEther(amountOut));
    } catch (error) {
      console.error("Error calculating expected sell amount:", error);
      setExpectedSellAmount(null);
    }
  };

  // Function to calculate expected token output from ETH input
  const calculateExpectedBuyAmount = async (amountIn) => {
    if (!amountIn || isNaN(amountIn) || Number(amountIn) <= 0) {
      setExpectedBuyAmount(null);
      return;
    }

    try {
      const amountInWei = ethers.utils.parseEther(amountIn.toString());
      const basedFoundryContract = new ethers.Contract(BASED_FOUNDRY_ADDRESS, BasedFoundryABI.abi, infuraProvider);
      const amountOut = await basedFoundryContract.calcAmountOutFromEth(address, amountInWei);
      setExpectedBuyAmount(ethers.utils.formatEther(amountOut));
    } catch (error) {
      console.error("Error calculating expected buy amount:", error);
      setExpectedBuyAmount(null);
    }
  };

  // Function to handle Buy Percentage Button Clicks
  const handleBuyPercentageClick = (percentage) => {
    if (!ethBalance) return;
    const balance = parseFloat(ethBalance);
    const calculatedAmount = ((balance * percentage) / 100).toFixed(5);
    setBuyAmount(calculatedAmount);
    calculateExpectedBuyAmount(calculatedAmount);
  };

  // Function to handle Sell Percentage Button Clicks
  const handleSellPercentageClick = (percentage) => {
    if (!tokenBalance) return;
    const balance = parseFloat(tokenBalance);
    const calculatedAmount = ((balance * percentage) / 100).toFixed(5);
    setSellAmount(calculatedAmount);
    calculateExpectedSellAmount(calculatedAmount);
  };

  // Polling Effect for Expected Buy Amount
  useEffect(() => {
    const interval = setInterval(async () => {
      if (buyAmount) {
        try {
          const newExpectedBuyAmount = await basedFoundryContract.calcAmountOutFromEth(address, ethers.utils.parseEther(buyAmount));
          const formattedNewBuyAmount = ethers.utils.formatEther(newExpectedBuyAmount);

          // Compare with previous expected buy amount
          if (formattedNewBuyAmount !== expectedBuyAmount) {
            setExpectedBuyAmount(formattedNewBuyAmount);
            setBuyFlash(true);
            setPreviousExpectedBuyAmount(formattedNewBuyAmount);
            // Remove flash after animation duration (e.g., 500ms)
            setTimeout(() => setBuyFlash(false), 500);
          }
        } catch (error) {
          console.error('Error polling expected buy amount:', error);
        }
      }
    }, REFRESH_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [buyAmount, expectedBuyAmount, basedFoundryContract, address]);

  // Polling Effect for Expected Sell Amount
  useEffect(() => {
    const interval = setInterval(async () => {
      if (sellAmount) {
        try {
          const newExpectedSellAmount = await basedFoundryContract.calcAmountOutFromToken(address, ethers.utils.parseEther(sellAmount));
          const formattedNewSellAmount = ethers.utils.formatEther(newExpectedSellAmount);

          // Compare with previous expected sell amount
          if (formattedNewSellAmount !== expectedSellAmount) {
            setExpectedSellAmount(formattedNewSellAmount);
            setSellFlash(true);
            setPreviousExpectedSellAmount(formattedNewSellAmount);
            // Remove flash after animation duration (e.g., 500ms)
            setTimeout(() => setSellFlash(false), 500);
          }
        } catch (error) {
          console.error('Error polling expected sell amount:', error);
        }
      }
    }, REFRESH_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [sellAmount, expectedSellAmount, basedFoundryContract, address]);

  if (loading) {
    return <p style={{ padding: '50px', boxSizing: 'border-box' }}>loading token data...</p>;
  }

  if (error) {
    return <p style={{ color: 'red', padding: '50px', boxSizing: 'border-box' }}>error loading token, try reloading the page</p>;
  }

  if (!tokenData) {
    return <p style={{ padding: '50px', boxSizing: 'border-box' }} >no data available for this token, try reloading the page</p>;
  }

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontFamily: 'Helvetica Neue, sans-serif',
        minHeight: '100vh',
        width: '100vw',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, width: '90vw' }}>
        {/* Left Column: Chart or DexToolsWidget */}
        <div style={{ flex: 2, paddingRight: '40px' }}>
          {/* Check if tokenData.isGraduated is true */}
          {tokenData.isGraduated && uniswapPairAddress ? (
            <DexToolsWidget pairAddress={uniswapPairAddress} />
          ) : (
            <>
              {/* Token Info */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between', width: '50vw' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                  {/* Token Image */}
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt={`${tokenData.name} logo`}
                      style={{ width: '50px', height: '50px', marginRight: '20px', borderRadius: '5px' }}
                    />
                  )}
                  <h2 style={{ fontWeight: 'bold', fontSize: '32px' }}>
                    {tokenData.name} (${tokenData.symbol})
                  </h2>
                </div>
                <p style={{ fontSize: '18px' }}>
                  market cap: {parseFloat(tokenData.mcap).toFixed(6)} eth
                </p>
              </div>
              {/* Price Chart */}
              <div ref={priceChartContainerRef} style={{ width: '100%', height: '600px' }} />
            </>
          )}
        </div>
        {/* Right Column: Buy/Sell Panel */}
        <div
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Buy Tokens Section */}
          <div style={{ marginBottom: '40px' }}>
            <h3 style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '20px' }}>
              buy {tokenData.symbol}
            </h3>
            <form
              onSubmit={handleBuy}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <label style={{ fontSize: '18px' }}>
                amount to spend (eth)
                <input
                  type="number"
                  step="0.00001"
                  value={buyAmount}
                  onChange={(e) => {
                    setBuyAmount(e.target.value);
                    calculateExpectedBuyAmount(e.target.value); // Update expected amount on change
                  }}
                  required
                  style={{
                    padding: '10px',
                    width: '100%',
                    marginTop: '10px',
                    fontSize: '16px',
                    border: '1px solid #e0e0e0',
                    boxSizing: 'border-box',
                  }}
                  min="0"
                />
              </label>
              {/* Buy Percentage Buttons */}
              <div style={{ display: 'flex', gap: '10px', marginTop: '10px', flexWrap: 'wrap' }}>
                {[10, 25, 50, 75, 100].map((percentage) => (
                  <button
                    key={percentage}
                    type="button"
                    onClick={() => handleBuyPercentageClick(percentage)}
                    style={{
                      padding: '8px 12px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      fontSize: '14px',
                    }}
                  >
                    {percentage}%
                  </button>
                ))}
              </div>
              <button
                type="submit"
                disabled={!buyAmount || transactionStatus === 'buying' || transactionStatus === 'selling'}
                style={{
                  padding: '15px',
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                {transactionStatus === 'buying' ? 'Buying...' : 'Buy'}
              </button>
            </form>
            {/* Display ETH balance */}
            {ethBalance && (
              <p style={{ marginTop: '10px', fontSize: '14px' }}>
                eth balance: {parseFloat(ethBalance).toFixed(4)} eth
              </p>
            )}
            {/* Display expected amount of tokens with flash effect */}
            {expectedBuyAmount !== null && (
              <p style={{ marginTop: '10px', fontSize: '14px', fontWeight: 'bold' }}>
                you receive:{' '}~
                <span className={`flash ${buyFlash ? 'active' : ''}`}>
                  {expectedBuyAmount} {tokenData.symbol}
                </span>
              </p>
            )}
          </div>

          {/* Sell Tokens Section */}
          <div>
            <h3 style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '20px' }}>
              sell {tokenData.symbol}
            </h3>
            <form
              onSubmit={handleSell}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <label style={{ fontSize: '18px' }}>
                amount to sell ({tokenData.symbol})
                <input
                  type="number"
                  step="0.00001"
                  value={sellAmount}
                  onChange={(e) => {
                    setSellAmount(e.target.value);
                    calculateExpectedSellAmount(e.target.value); // Update expected amount on change
                  }}
                  required
                  style={{
                    padding: '10px',
                    width: '100%',
                    marginTop: '10px',
                    fontSize: '16px',
                    border: '1px solid #e0e0e0',
                    boxSizing: 'border-box',
                  }}
                  min="0"
                />
              </label>
              {/* Sell Percentage Buttons */}
              <div style={{ display: 'flex', gap: '10px', marginTop: '10px', flexWrap: 'wrap' }}>
                {[10, 25, 50, 75, 100].map((percentage) => (
                  <button
                    key={percentage}
                    type="button"
                    onClick={() => handleSellPercentageClick(percentage)}
                    style={{
                      padding: '8px 12px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      fontSize: '14px',
                    }}
                  >
                    {percentage}%
                  </button>
                ))}
              </div>
              <button
                type="submit"
                disabled={!sellAmount || transactionStatus === 'buying' || transactionStatus === 'selling'}
                style={{
                  padding: '15px',
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                {transactionStatus === 'selling' ? 'Selling...' : 'Sell'}
              </button>
            </form>
            {/* Display token balance */}
            {tokenBalance && (
              <p style={{ marginTop: '10px', fontSize: '14px' }}>
                {tokenData.symbol} balance: {parseFloat(tokenBalance).toFixed(4)} {tokenData.symbol}
              </p>
            )}
            {/* Display expected amount of ETH with flash effect */}
            {expectedSellAmount !== null && (
              <p style={{ marginTop: '10px', fontSize: '14px', fontWeight: 'bold' }}>
                you receive:{' '}~
                <span className={`flash ${sellFlash ? 'active' : ''}`}>
                  {expectedSellAmount} eth
                </span>
              </p>
            )}
          </div>

          {/* Transaction Status Message */}
          {transactionStatus && (
            <div
              style={{
                marginTop: '20px',
                padding: '10px',
                borderRadius: '5px',
                backgroundColor:
                  transactionStatus === 'success'
                    ? '#d4edda'
                    : transactionStatus === 'error'
                      ? '#f8d7da'
                      : '#fff3cd',
                color:
                  transactionStatus === 'success'
                    ? '#155724'
                    : transactionStatus === 'error'
                      ? '#721c24'
                      : '#856404',
                maxWidth: '400px', // Adjust the maxWidth as needed
                wordWrap: 'break-word',
              }}
            >
              {transactionMessage}
            </div>
          )}
          {/* Bonding Curve Progress */}
          <div style={{ marginBottom: '20px' }}>
            <h3 style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '10px' }}>
              bonding curve progress
            </h3>
            {ethReserve && graduationThreshold ? (
              <div>
                <p style={{ fontSize: '16px' }}>
                  {parseFloat(ethReserve).toFixed(4)} eth /{' '}
                  {parseFloat(graduationThreshold).toFixed(4)} eth
                </p>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: '#e0e0e0',
                    height: '20px',
                    border: '1px solid #e0e0e0',
                  }}
                >
                  <div
                    style={{
                      width: `${progressPercentage}%`,
                      height: '100%',
                      backgroundColor: '#26a69a',
                    }}
                  />
                </div>
                <p style={{ fontSize: '16px', marginTop: '5px' }}>
                  {progressPercentage.toFixed(2)}% Completed
                </p>
              </div>
            ) : (
              <p>loading bonding curve progress...</p>
            )}
          </div>
          {tokenData.isGraduated && uniswapPairAddress && (
            <a
              href={`https://dexscreener.com/base/${uniswapPairAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                style={{
                  marginTop: '20px',
                  padding: '15px',
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                DexScreener
              </button>
            </a>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '90vw' }}>
        <div style={{ flex: '2', display: 'flex', flexDirection: 'column' }}>
          {/* Token Description */}
          <h3 style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '10px' }}>
            description
          </h3>
          {tokenData.description && (
            <p style={{ fontSize: '14px', marginBottom: '20px', textAlign: 'left' }}>{tokenData.description}</p>
          )}
          <Comments tokenAddress={address} />
        </div>
        {/* Contract Information */}
        <div
          style={{
            marginTop: '40px',
            borderTop: '1px solid #e0e0e0',
            paddingTop: '20px',
            flex: '1',
          }}
        >
          {extendedData && (
            <div>
              {/* Social Media Links */}
              {extendedData.socialMedia &&
                (extendedData.socialMedia.twitter ||
                  extendedData.socialMedia.telegram ||
                  extendedData.socialMedia.discord) && (
                  <div style={{ fontSize: '14px', marginBottom: '10px', textAlign: 'right' }}>
                    {extendedData.website && (
                      <a
                        href={extendedData.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          backgroundColor: 'lightblue',
                          color: 'black',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          textDecoration: 'none',
                          display: 'inline-block',
                        }}
                      >
                        Website
                      </a>
                    )}
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                      {extendedData.socialMedia.twitter && (
                        <li style={{ marginBottom: '10px' }}>
                          <a
                            href={extendedData.socialMedia.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              backgroundColor: 'lightblue',
                              color: 'black',
                              padding: '5px 10px',
                              borderRadius: '4px',
                              textDecoration: 'none',
                              display: 'inline-block',
                            }}
                          >
                            X / Twitter
                          </a>
                        </li>
                      )}
                      {extendedData.socialMedia.telegram && (
                        <li style={{ marginBottom: '10px' }}>
                          <a
                            href={extendedData.socialMedia.telegram}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              backgroundColor: 'lightblue',
                              color: 'black',
                              padding: '5px 10px',
                              borderRadius: '4px',
                              textDecoration: 'none',
                              display: 'inline-block',
                            }}
                          >
                            Telegram
                          </a>
                        </li>
                      )}
                      {extendedData.socialMedia.discord && (
                        <li style={{ marginBottom: '10px' }}>
                          <a
                            href={extendedData.socialMedia.discord}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              backgroundColor: 'lightblue',
                              color: 'black',
                              padding: '5px 10px',
                              borderRadius: '4px',
                              textDecoration: 'none',
                              display: 'inline-block',
                            }}
                          >
                            Discord
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
            </div>
          )}

          {/* Contract Information */}
          <div style={{ fontSize: '14px', marginBottom: '10px', textAlign: 'left' }}>
            <strong style={{ display: 'inline-block', width: '200px', textAlign: 'left' }}>Contract Address:</strong>
            <a
              href={`https://basescan.org/address/${tokenData.address}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: 'lightblue',
                color: 'black',
                padding: '5px 10px',
                borderRadius: '4px',
                textDecoration: 'none',
                display: 'inline-block',
              }}
            >
              {tokenData.address.substring(0, 6)}...{tokenData.address.substring(tokenData.address.length - 4)}
            </a>
          </div>
          <div style={{ fontSize: '14px', marginBottom: '10px', textAlign: 'left' }}>
            <strong style={{ display: 'inline-block', width: '200px', textAlign: 'left' }}>Creator:</strong>
            <a
              href={`https://basescan.org/address/${tokenData.creator}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: 'lightblue',
                color: 'black',
                padding: '5px 10px',
                borderRadius: '4px',
                textDecoration: 'none',
                display: 'inline-block',
              }}
            >
              {tokenData.creator.substring(0, 6)}...{tokenData.creator.substring(tokenData.creator.length - 4)}
            </a>
          </div>
          <div style={{ fontSize: '14px', marginBottom: '10px', textAlign: 'left' }}>
            <strong style={{ display: 'inline-block', width: '200px', textAlign: 'left' }}>Pushed to Uniswap:</strong>
            <span style={{ textAlign: 'right' }}>{tokenData.isGraduated ? 'Yes' : 'No'}</span>
          </div>
          {tokenData.isGraduated && uniswapPairAddress && (
            <div style={{ fontSize: '14px', marginBottom: '10px', textAlign: 'left' }}>
              <strong style={{ display: 'inline-block', width: '200px', textAlign: 'left' }}>Uniswap V2 Pair Address:</strong>
              <a
                href={`https://basescan.org/address/${uniswapPairAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  backgroundColor: 'lightblue',
                  color: 'black',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  textDecoration: 'none',
                  display: 'inline-block',
                }}
              >
                {uniswapPairAddress.substring(0, 6)}...{uniswapPairAddress.substring(uniswapPairAddress.length - 4)}
              </a>
            </div>
          )}

          <TopHoldersList tokenAddress={address} foundryAddress={BASED_FOUNDRY_ADDRESS} />
        </div>
      </div>
    </div>
  );
};

export default TokenPage;
