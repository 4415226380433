// App.js
import React, { useState, useEffect, useContext } from 'react';
import GlobalStyle from './GlobalStyle';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { Web3Context } from './Web3Context';

// Import Components and ABIs
import TokenPage from './TokenPage';
import DeployToken from './DeployToken';
import BasedFoundryABI from './abi/BasedFoundry.json';
import BasedTokenABI from './abi/BasedToken.json';
import Footer from './Footer';
import About from './About';
import Disclaimer from './Disclaimer';

const App = () => {
  const { wallets, provider, signer, connectWallet, disconnectWallet } = useContext(Web3Context);
  const [writeContract, setWriteContract] = useState(null);
  const [recentlyCreatedTokens, setRecentlyCreatedTokens] = useState([]);
  const [recentlyTradedTokens, setRecentlyTradedTokens] = useState([]);
  const [error, setError] = useState('');
  const [isInitialized, setIsInitialized] = useState(true); // Set to true since we're using the subgraph
  const [isLoading, setIsLoading] = useState(false);

  // Initialize write-enabled contract when signer is available
  useEffect(() => {
    if (signer) {
      const writeContractInstance = new ethers.Contract(
        process.env.REACT_APP_BASED_FOUNDRY_ADDRESS,
        BasedFoundryABI.abi,
        signer
      );
      setWriteContract(writeContractInstance);
      console.log('Write-enabled contract initialized with signer.');
    } else {
      setWriteContract(null);
    }
  }, [signer]);

  // Load tokens when initialized
  useEffect(() => {
    if (isInitialized) {
      loadTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  const loadTokens = async () => {
    setIsLoading(true);
    setError('');

    try {
      console.log('Loading tokens from subgraph...');
      const query = `
        {
          tokenCreateds(first: 10, orderBy: blockTimestamp, orderDirection: desc) {
            token
            creator
          }
          buys(first: 20, orderBy: blockTimestamp, orderDirection: desc) {
            token
          }
          sells(first: 20, orderBy: blockTimestamp, orderDirection: desc) {
            token
          }
        }
      `;

      const response = await fetch('https://api.studio.thegraph.com/query/82775/basedfoundry/version/latest', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      });

      const data = await response.json();
      if (data.errors) {
        throw new Error(data.errors[0].message);
      }

      // Initialize provider for blockchain data
      const infuraUrl = process.env.REACT_APP_BASE_INFURA_URL;
      const infuraProvider = new ethers.providers.JsonRpcProvider(infuraUrl);

      // Fetch token details for recently created tokens
      const createdTokenAddresses = data.data.tokenCreateds.map((event) => ({
        address: event.token,
        creator: event.creator,
      }));

      const createdTokensData = await Promise.all(
        createdTokenAddresses.map(async ({ address, creator }) => {
          const tokenContract = new ethers.Contract(address, BasedTokenABI.abi, infuraProvider);
          const metadata = await tokenContract.getMetadata();
          const pool = await getPoolData(address);

          // Parse extended data to get imageUrl
          let imageUrl = '';
          try {
            const extendedData = JSON.parse(metadata.extended);
            if (extendedData && extendedData.image) {
              if (extendedData.image.startsWith('/ipfs/')) {
                imageUrl = `https://gateway.pinata.cloud${extendedData.image}`;
              } else {
                imageUrl = extendedData.image;
              }
            }
          } catch (e) {
            console.error('Failed to parse extended data:', e);
          }

          return {
            address,
            name: metadata.name,
            symbol: metadata.symbol,
            description: metadata.description,
            mcap: ethers.utils.formatEther(pool.lastMcapInEth),
            creator,
            imageUrl,
          };
        })
      );

      setRecentlyCreatedTokens(createdTokensData);

      // Fetch token details for recently traded tokens (buys and sells)
      const tradedTokenAddressesSet = new Set();
      data.data.buys.forEach((event) => tradedTokenAddressesSet.add(event.token));
      data.data.sells.forEach((event) => tradedTokenAddressesSet.add(event.token));
      const tradedTokenAddresses = Array.from(tradedTokenAddressesSet);

      const tradedTokensData = await Promise.all(
        tradedTokenAddresses.map(async (address) => {
          const tokenContract = new ethers.Contract(address, BasedTokenABI.abi, infuraProvider);
          const metadata = await tokenContract.getMetadata();
          const pool = await getPoolData(address);

          // Parse extended data to get imageUrl
          let imageUrl = '';
          try {
            const extendedData = JSON.parse(metadata.extended);
            if (extendedData && extendedData.image) {
              if (extendedData.image.startsWith('/ipfs/')) {
                imageUrl = `https://gateway.pinata.cloud${extendedData.image}`;
              } else {
                imageUrl = extendedData.image;
              }
            }
          } catch (e) {
            console.error('Failed to parse extended data:', e);
          }

          return {
            address,
            name: metadata.name,
            symbol: metadata.symbol,
            description: metadata.description,
            mcap: ethers.utils.formatEther(pool.lastMcapInEth),
            creator: pool.creator,
            imageUrl,
          };
        })
      );

      setRecentlyTradedTokens(tradedTokensData);
    } catch (err) {
      console.error('Error in loadTokens:', err);
      setError('Error loading tokens: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getPoolData = async (tokenAddress) => {
    // Fetch pool data from BasedFoundry contract
    const infuraUrl = process.env.REACT_APP_BASE_INFURA_URL;
    const infuraProvider = new ethers.providers.JsonRpcProvider(infuraUrl);

    const readContract = new ethers.Contract(
      process.env.REACT_APP_BASED_FOUNDRY_ADDRESS,
      BasedFoundryABI.abi,
      infuraProvider
    );

    const pool = await readContract.getPool(tokenAddress);
    return pool;
  };

  // Helper function to abbreviate the address
  function abbreviateAddress(address) {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }

  // Helper function to calculate tokens per row
  const getTokensPerRow = () => {
    const tokenCardMinWidth = 200; // Minimum width of each token card in pixels
    const gap = 20; // Gap between cards in pixels
    const viewportWidth = window.innerWidth;

    // Calculate total width taken by one token card including the gap
    const totalCardWidth = tokenCardMinWidth + gap;

    // Calculate tokens per row
    const tokensPerRow = Math.floor((viewportWidth + gap) / totalCardWidth);

    return tokensPerRow;
  };

  const getRandomPastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 70 + Math.random() * 10; // Keep saturation in a range to ensure pastel colors
    const lightness = 85 + Math.random() * 10; // Lightness in a higher range for pastel
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };


  return (
    <>
      <GlobalStyle />
      <Router>
        <div
          style={{
            width: '100vw',
            margin: '0', // Removed 'auto' to prevent horizontal margins
            padding: '0', // Removed padding to allow grid to fill 100% width
            boxSizing: 'border-box',
            minHeight: '100vh',
            backgroundColor: 'white',
          }}
        >
          {/* Header */}
          <div
            style={{
              width: '100%',
              padding: '20px', // Added padding here to maintain spacing in the header
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
          >
            <Link to="/" style={{ textDecoration: 'none', color: '#333' }}>
              <h1 style={{ fontSize: '24px', margin: 0 }}>based</h1>
            </Link>

            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {/* Wallet Connection Section */}
              <div>
                {wallets.length > 0 ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <p style={{ margin: 0 }}>
                      {wallets[0].accounts[0].address
                        ? `${wallets[0].accounts[0].address.slice(0, 6)}...${wallets[0].accounts[0].address.slice(-4)}`
                        : ''}
                    </p>
                    <button
                      onClick={() => disconnectWallet(wallets[0].label)}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#141414',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '5px',
                      }}
                    >
                      Disconnect
                    </button>
                    <Link to="/new" style={{ textDecoration: 'none', color: '#333' }}>
                      <button
                        style={{
                          padding: '10px 20px',
                          backgroundColor: '#141414',
                          color: 'white',
                          border: 'none',
                          cursor: 'pointer',
                          borderRadius: '5px',
                        }}
                      >
                        Create Token
                      </button>
                    </Link>
                  </div>
                ) : (
                  <button
                    onClick={connectWallet}
                    style={{
                      padding: '10px 20px',
                      backgroundColor: '#141414',
                      color: 'white',
                      border: 'none',
                      cursor: 'pointer',
                      borderRadius: '5px',
                    }}
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <div
              style={{
                backgroundColor: '#ffcccc',
                padding: '10px',
                marginBottom: '20px',
                borderRadius: '5px',
              }}
            >
              <strong>Error:</strong> {error}
            </div>
          )}

          {/* Define Routes */}
          <Routes>
            {/* Home Route */}
            <Route
              path="/"
              element={
                <>
                  <About />
                  {/* Recently Traded Tokens Section */}
                  <div style={{ background: 'lightgrey', padding: '20px', boxSizing: 'border-box', margin: '20px' }}>
                    <h2 style={{ fontSize: '24px', marginBottom: '20px', padding: '0 20px' }}>
                      recently traded
                    </h2>
                    {isLoading ? (
                      <p>Loading tokens...</p>
                    ) : recentlyTradedTokens.length === 0 ? (
                      <p>No tokens found.</p>
                    ) : (
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                          gap: '20px',
                          width: '100%', // Ensure the grid fills the full width
                          padding: '0 20px', // Added horizontal padding
                          boxSizing: 'border-box',
                        }}
                      >
                        {recentlyTradedTokens
                          .slice(0, getTokensPerRow() * 3) // Limit to 3 rows
                          .map((token) => (
                            <Link
                              to={`/token/${token.address}`}
                              style={{ textDecoration: 'none', color: 'inherit' }}
                              key={token.address}
                            >
                              <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', background: getRandomPastelColor(), height: '200px', width: '200px', overflow: 'hidden' }}>
                                {/* Top section with image and token info */}
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                  <img
                                    src={token.imageUrl}
                                    alt={`${token.name} logo`}
                                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px', marginRight: '10px' }}
                                  />
                                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h3 style={{ fontSize: '16px', margin: 0 }}>{token.name}</h3>
                                    <p style={{ fontSize: '14px', margin: 0, color: '#555' }}>{token.symbol}</p>
                                  </div>
                                </div>

                                {/* Description, Market Cap, and Creator */}
                                <p
                                  style={{
                                    fontSize: '14px',
                                    color: '#555',
                                    marginBottom: '5px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    height: '60px',
                                  }}
                                >
                                  {token.description}
                                </p>
                                <p style={{ fontSize: '14px', color: '#555', marginBottom: '5px' }}>
                                  <strong>market cap:</strong> {parseFloat(token.mcap).toFixed(4)} ETH
                                </p>
                                <p style={{ fontSize: '14px', color: '#555' }}>
                                  <strong>creator:</strong> {abbreviateAddress(token.creator)}
                                </p>
                              </div>

                            </Link>
                          ))}
                      </div>
                    )}
                  </div>

                  {/* Recently Created Tokens Section */}
                  <div style={{ background: 'lightgrey', padding: '20px', boxSizing: 'border-box', margin: '20px' }}>
                    <h2 style={{ fontSize: '24px', marginBottom: '20px', padding: '0 20px' }}>
                      recently created
                    </h2>
                    {isLoading ? (
                      <p>Loading tokens...</p>
                    ) : recentlyCreatedTokens.length === 0 ? (
                      <p>No tokens found.</p>
                    ) : (
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                          gap: '20px',
                          width: '100%', // Ensure the grid fills the full width
                          padding: '0 20px', // Added horizontal padding
                          boxSizing: 'border-box',
                        }}
                      >
                        {recentlyCreatedTokens
                          .slice(0, getTokensPerRow() * 3) // Limit to 3 rows
                          .map((token) => (
                            <Link
                              to={`/token/${token.address}`}
                              style={{ textDecoration: 'none', color: 'inherit' }}
                              key={token.address}
                            >
                              <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', background: getRandomPastelColor(), height: '200px', width: '200px', overflow: 'hidden' }}>
                                {/* Top section with image and token info */}
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                  <img
                                    src={token.imageUrl}
                                    alt={`${token.name} logo`}
                                    style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px', marginRight: '10px' }}
                                  />
                                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h3 style={{ fontSize: '16px', margin: 0 }}>{token.name}</h3>
                                    <p style={{ fontSize: '14px', margin: 0, color: '#555' }}>{token.symbol}</p>
                                  </div>
                                </div>

                                {/* Description, Market Cap, and Creator */}
                                <p
                                  style={{
                                    fontSize: '14px',
                                    color: '#555',
                                    marginBottom: '5px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    height: '60px',
                                  }}
                                >
                                  {token.description}
                                </p>
                                <p style={{ fontSize: '14px', color: '#555', marginBottom: '5px' }}>
                                  <strong>Market Cap:</strong> {parseFloat(token.mcap).toFixed(4)} ETH
                                </p>
                                <p style={{ fontSize: '14px', color: '#555' }}>
                                  <strong>Creator:</strong> {abbreviateAddress(token.creator)}
                                </p>
                              </div>

                            </Link>
                          ))}
                      </div>
                    )}
                  </div>

                </>
              }
            />

            <Route
              path="/new"
              element={<DeployToken writeContract={writeContract} loadTokens={loadTokens} />}
            />

            <Route path="/token/:address" element={<TokenPage />} />

            <Route
              path="/about"
              element={<About />}
            />

            <Route
              path="/disclaimer"
              element={<Disclaimer />}
            />

          </Routes>
        </div>
      </Router>
      <Footer />
    </>
  );
};

export default App;