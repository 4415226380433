// Web3Context.js
import React, { createContext, useState, useEffect } from 'react';
import onboard from './Web3Onboard';
import { ethers } from 'ethers';

export const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
  const [wallets, setWallets] = useState([]);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);

  useEffect(() => {
    // Subscribe to wallet events
    const subscriptions = [
      onboard.state.select('wallets').subscribe(setWallets),
    ];

    // Cleanup subscriptions on unmount
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  useEffect(() => {
    // Check if there's a wallet connected in localStorage
    const savedWallets = JSON.parse(localStorage.getItem('connectedWallets'));
    if (savedWallets && savedWallets.length > 0) {
      onboard.connectWallet({ autoSelect: { label: savedWallets[0], disableModals: true } });
    }
  }, []);

  useEffect(() => {
    if (wallets.length > 0) {
      const wallet = wallets[0];
      const ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any');
      const signer = ethersProvider.getSigner();

      setProvider(ethersProvider);
      setSigner(signer);

      // Save the connected wallet to localStorage
      localStorage.setItem('connectedWallets', JSON.stringify(wallets.map((wallet) => wallet.label)));
    } else {
      setProvider(null);
      setSigner(null);

      // Clear localStorage when no wallets are connected
      localStorage.removeItem('connectedWallets');
    }
  }, [wallets]);

  const connectWallet = async () => {
    const connectedWallets = await onboard.connectWallet();
    
    if (connectedWallets.length > 0) {
      const walletChainId = connectedWallets[0].chains[0].id;
      const baseChainId = '0x2105';
  
      if (walletChainId !== baseChainId) {
        try {
          await onboard.setChain({
            chainId: baseChainId
          });
        } catch (error) {
          console.error("Error switching chains:", error);
        }
      }
      setWallets(connectedWallets);
    }
  };  

  const disconnectWallet = async (walletLabel) => {
    await onboard.disconnectWallet({ label: walletLabel });
    localStorage.removeItem('connectedWallets'); // Clear the wallet from local storage
  };

  return (
    <Web3Context.Provider
      value={{
        wallets,
        provider,
        signer,
        connectWallet,
        disconnectWallet,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
