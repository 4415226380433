// GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: white;
    margin: 0;
    padding: 0;
    color: #141414;
  }
`;

export default GlobalStyle;